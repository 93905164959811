const getCustomConfig = ():any => ((window as any).customConfig || {});

const config: {
    jitsiFQDN: string,
    backend: string,
    roomPrefix: string,
    nodeEnv: string,
    baseName: string,
    jitsiJWT?: string,
    bmiLandingURL: string,
    version: string
    allowedCallbackUrls: string
} = {
    "nodeEnv": process.env.NODE_ENV,
    "version": process.env.REACT_APP_VERSION || "0",
    "jitsiFQDN": getCustomConfig().REACT_APP_JITSI_FQDN ||  process.env.REACT_APP_JITSI_FQDN,
    "backend": getCustomConfig().REACT_APP_BACKEND || process.env.REACT_APP_BACKEND || 'http://localhost:8081',
    "roomPrefix": getCustomConfig().REACT_APP_ROOM_PREFIX || process.env.REACT_APP_ROOM_PREFIX || 'videotest',
    "baseName": process.env.REACT_APP_BASENAME || '/',
    "jitsiJWT": process.env.REACT_APP_DEBUG_JITSI_JWT,
    "bmiLandingURL": getCustomConfig().REACT_APP_BMI_LANDING_URL || process.env.REACT_APP_BMI_LANDING_URL,
    "allowedCallbackUrls": getCustomConfig().REACT_APP_ALLOWED_CALLBACK_URLS || process.env.REACT_APP_ALLOWED_CALLBACK_URLS
};

export const  nextButtonText:{[key: string]: string} = {
    video: 'weiter zum Videotest',
    mic: 'weiter zum Mikrofontest',
    speaker:`weiter zum Lautsprechertest`,
    summary: 'Test beenden'
}



export default config;