import {config} from "../routes/routes";

type TTestPlan = {
    id: string,
    route: string,
    next: TTestPlan | null,
    prev: TTestPlan | null
}

export function getTestPlan() {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    const DEFAULT_TEST_PARAM = 'video,mic,speaker';

    const parse = (param: string | null): TTestPlan[] => {
        if (!param) return [];
        return param
            .split(',')
            .map((o) => o.trim())
            .filter(o => !!config[o])
            .map(o => {
                return {
                    id: o,
                    route: config[o].route,
                    next: null,
                    prev: null
                }
            });
    }

    const plan = parse(searchParams.get('tests') || DEFAULT_TEST_PARAM) || [];

    for (let i = 0; i < plan.length; i++) {
        if (i + 1 < plan.length) {
            plan[i].next = plan[i + 1];
        }
        if (i - 1 >= 0) {
            plan[i].prev = plan[i - 1];
        }
    }

    return plan;
}