import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonsGroupProps } from "../utils/types";
import {config as configRoute} from "../routes/routes";
import {routeToCallback} from "../utils/routeUtils";

const ButtonsGroup = ({ text, navigation } : ButtonsGroupProps) => {

    const navigate = useNavigate();
   
    const clickNextHandler = () => {
        if (navigation.next === configRoute.callback.route) {
            routeToCallback();
            return;
        }

        navigate(navigation.next);
    };
    
    const clickBackHandler = () => {
        routeToCallback();
    }

    return (
        <Stack alignItems={{ xs : 'center' }} spacing={2} direction={{ xs: 'column', md: 'row' }} className="buttons-group">
            {text.back && <Button className="button-back" variant="outlined" onClick={clickBackHandler}>{text.back}</Button>}
            {text.next && <Button className="button-next" variant="contained" onClick={clickNextHandler}>{text.next}</Button>}
        </Stack>
    );
}


export default ButtonsGroup;