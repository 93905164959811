import config from "./config";

export const routeToCallback = () => {
    const allowed = config.allowedCallbackUrls?.split(',') || [];
    const url = new URL(window.location.href);

    if (url.searchParams.has("callback")) {
        const callbackUrl = url.searchParams.get("callback") || '';

        const isAllowed = allowed.length > 0
            ? !!allowed.find(o => callbackUrl.startsWith(o))
            : true;

        if (isAllowed) {
            window.location.href = `${url.searchParams.get("callback")}`;
            return;
        }
    }

    window.location.href = config.bmiLandingURL;
}