import {Navigate} from "react-router-dom";
import {config} from "../routes/routes";


const RootView = () => {
    return (
            <Navigate to={config.landing.route} replace />
    );

}

export default RootView;